import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import store from './redux/store/store'
import App from './app/App'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import axios from 'axios'
import './axiosInstance'
import LanguageProvider from './context/LanguageProvider'
import generateAntdTheme from './assets/styles/theming/theme'
import { BASE_URL } from './constants'
import { HelmetProvider } from 'react-helmet-async'
import { ConfigProvider as PhoneInputConfigProvider } from 'antd-country-phone-input'
import en from 'world_countries_lists/data/countries/en/world.json'
import FallbackLoader from 'components/common/fallback-loader'
import './i18n/i18n'
import NotificationProvider from 'context/NotificationProvider'
import 'flagpack/dist/flagpack.css'
import { Spin } from 'antd'
Spin.setDefaultIndicator(<SpinLoader />)
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import TourRefsProvider from 'context/TourRefsProvider'
import StripeProvider from 'context/StripeProvider'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import SpinLoader from 'components/common/fallback-loader/SpinLoader'
import UserDetailsProvider from 'context/UserDetailsProvider'
import './index.scss'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.guess()

axios.defaults.baseURL = BASE_URL

ReactDOM.createRoot(document.getElementById('root')).render(
  <StripeProvider>
    <UserDetailsProvider>
      <TourRefsProvider>
        <LanguageProvider>
          <Provider store={store}>
            <PhoneInputConfigProvider
              locale={en}
              areaMapper={(area) => ({
                ...area,
                emoji: <span className={`fp ${area.short.toLowerCase()}`} />
              })}
            >
              <ConfigProvider
                theme={generateAntdTheme()}
                getPopupContainer={(trigger) => {
                  const className = trigger?.className || ''
                  if (typeof className === 'string') {
                    if (className.includes('ant-select')) {
                      return trigger.parentElement
                    }
                    if (className.includes('common-antd-dropdown')) {
                      return (
                        document.querySelector('.case-layout-wrapper') ||
                        document.querySelector('.main-layout-container')
                      )
                    }
                  }
                }}
              >
                <NotificationProvider>
                  <HelmetProvider>
                    <Suspense fallback={<FallbackLoader />}>
                      <App />
                    </Suspense>
                  </HelmetProvider>
                </NotificationProvider>
              </ConfigProvider>
            </PhoneInputConfigProvider>
          </Provider>
        </LanguageProvider>
      </TourRefsProvider>
    </UserDetailsProvider>
  </StripeProvider>
)
