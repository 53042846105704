import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Flex,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import BreadCrumbs from '../bread-crumbs'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  SearchOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ReadOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import { ArrowDownIcon, GlobalIcon, NotificationIcon, BroadCastIcon } from 'assets/svg'
import VerifiedBadge from '../verified-badge/VerifiedBadge'
import { useTranslation } from 'react-i18next'
import NotificationWidget from '../notification-widget/Index'
import useClickOutside from 'hooks/useClickOutside'
import { useTourComponentRefs } from 'context/TourRefsProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import SynappTourDialog from './SynappTourDialog'
import { languageData, roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import {
  AuthService,
  CommonService,
  NotificationsWidgetService,
  ProfileInfoService
} from '../../../redux/services'
import { useDispatch, useSelector } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { setActiveProfile } from '../../../redux/reducer/activeProfileReducer'
import {
  clearCommonDataDetails,
  setSoFromGlobalSearchForPatients,
  setSoFromGlobalSearchForRequest
} from '../../../redux/reducer/commonReducer'
import BroadcastWidget from '../broadcast-widget'
import { debounce } from 'lodash'
import { clearGlobalSearchList } from '../../../redux/reducer/profileInfoReducer'
import { useLanguageChanger } from 'context/LanguageProvider'
import i18next from 'i18next'
import LogoutDialog from '../logout-dialog/LogoutDialog'
import './Navbar.scss'

const Navbar = ({ isPrimaryLayout, sidebarCollapsed, setSidebarCollapsed }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */
  /* location dependencies */
  const location = useLocation()
  /* location dependencies */

  /* language change dependencies */
  const { selectedLanguage, updateLanguage } = useLanguageChanger()
  /* language change dependencies */

  /* user details dependencies */
  const { selectedRole, userDetails, selectedProfileDetails } = useUserDetails()
  /* user details dependencies */

  /* define ref */
  const broadCastRef = useRef(null)
  const notificationRef = useRef(null)
  /* define ref */

  /* dispatch dependencies */
  const dispatch = useDispatch()
  const { getCommunity } = useSelector((state) => state.common)

  const globalSearchData = useSelector((state) => state.profileInfo.globalSearchList)
  const { getNotificationsList } = useSelector((state) => state.notificationsWidget)
  /* dispatch dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  const ref = useTourComponentRefs()

  /* define state */
  const [searchValue, setSearchValue] = useState(null)
  const [showContent, setShowContent] = useState(true)
  const { activeProfile } = useSelector((state) => state.activeProfile)
  /* define state */

  /* collapsed dependencies */
  const [collapsed, setCollapsed] = useState(false)
  const [showBroadCast, setShowBroadCast] = useState(false)
  /* collapsed dependencies */

  /* dialog dependencies */
  const [synappTourDialogOpen, setSynappTourDialogOpen] = useState(false)

  const handleOpenSynappTourDialog = () => {
    setSynappTourDialogOpen(true)
  }

  const handleCloseSynappTourDialog = () => {
    setSynappTourDialogOpen(false)
  }

  const synappTourDialogProps = useMemo(
    () => ({
      open: synappTourDialogOpen,
      onClose: handleCloseSynappTourDialog
    }),
    [synappTourDialogOpen]
  )
  /* dialog dependencies */

  useEffect(() => {
    function handleResizeFunction(e) {
      if (e.currentTarget.innerWidth <= 768) {
        setShowContent(false)
      } else {
        setShowContent(true)
      }
    }

    // Set initial state based on the current window width
    if (window.innerWidth <= 768) {
      setShowContent(false)
    } else {
      setShowContent(true)
    }

    window.addEventListener('resize', handleResizeFunction)
    return () => {
      window.removeEventListener('resize', handleResizeFunction)
    }
  }, [])

  /* open tour dialog if user is for the first time */
  useEffect(() => {
    if ([roles.physician, roles.hcp].includes(selectedRole)) {
      if (!userDetails?.is_tour_completed) handleOpenSynappTourDialog()
    }
  }, [selectedRole, userDetails])
  /* open tour dialog if user is for the first time */

  /* check for outside click to close the widget */
  useClickOutside(notificationRef, () => setCollapsed(false))
  useClickOutside(broadCastRef, () => setShowBroadCast(false))
  /* check for outside click to close the widget */

  /* dependencies for back button navigation */
  const navigateBackButton = (route) => {
    const prevPath = location.state?.prevPath || []
    return typeof prevPath !== 'string' && prevPath?.length > 0
      ? prevPath[prevPath.length - 1]
      : route
  }

  const navigateBackButtonBasedOnRole = {
    [roles.superAdmin]: navigateBackButton(AppRoutingConfig.DASHBOARD),
    [roles.physician]: navigateBackButton(AppRoutingConfig.HOME),
    [roles.hcp]: navigateBackButton(AppRoutingConfig.HOME),
    [roles.editor]: navigateBackButton(AppRoutingConfig.HOME),
    [roles.author]: navigateBackButton(AppRoutingConfig.HOME),
    [roles.patient]: navigateBackButton(AppRoutingConfig.HOME)
  }

  const navigationRoute = navigateBackButtonBasedOnRole[selectedRole]
  /* dependencies for back button navigation */

  /* user dependencies */
  const userDropdownItems = [
    {
      key: '1',
      label: (
        <Typography.Title level={4} style={{ margin: 0, fontWeight: 500 }}>
          {t('SignIn.GoToProfile')}
        </Typography.Title>
      )
    },
    {
      key: '2',
      label: (
        <Typography.Title
          level={4}
          style={{ margin: 0, fontWeight: 500, color: 'var(--postal-red)' }}
        >
          {t('SignIn.Logout')}
        </Typography.Title>
      )
    },
    ![roles.superAdmin].includes(selectedRole) && {
      key: '3',
      label: (
        <div>
          <Divider plain style={{ margin: 0 }} />
          <Flex align="center" gap={'0.5rem'} style={{ marginTop: '0.5rem' }}>
            <Typography.Title level={4} style={{ margin: 0, fontWeight: 500 }}>
              {t('SignIn.UserId')}
            </Typography.Title>
            <Typography.Title level={4} style={{ margin: 0, fontWeight: 500 }}>
              {userDetails?.user_no}
            </Typography.Title>
          </Flex>
        </div>
      )
    }
  ]
  /* user dependencies */

  const handleGetData = () => {
    dispatch(CommonService.get_community({ payload: {}, showNotification, userDetails }))
  }

  useEffect(() => {
    if (!getCommunity?.data) handleGetData()
    dispatch(setActiveProfile(selectedProfileDetails))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*useEffect hook to fetch initial data when the component mounts */
  useEffect(() => {
    const getNotificationsListPayload = {
      filterType: '',
      page: 1,
      pageSize: 10
    }
    dispatch(
      NotificationsWidgetService.get_notifications_list({
        payload: getNotificationsListPayload,
        showNotification
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /*useEffect hook to fetch initial data when the component mounts */

  const handleGetGlobalData = (search) => {
    if (search?.length > 0) {
      const dataToBeSent = {
        search: search
      }
      dispatch(ProfileInfoService.global_search({ payload: dataToBeSent }))
    } else {
      dispatch(clearGlobalSearchList())
    }
  }
  //eslint-disable-next-line
  const debounceFn = useMemo(() => debounce(handleGetGlobalData, 500), [])

  const handleSearch = (newValue) => {
    debounceFn(newValue)
  }
  /* Handle Search Change */

  // Optionally handle onBlur to persist the search value
  const handleBlur = () => {
    setSearchValue(null)
    dispatch(clearGlobalSearchList())
  }

  // TODO : Search value to display when navigated to other pages
  const handleGlobalNavigate = (type) => {
    switch (type) {
      case 'physicians':
        navigate(AppRoutingConfig.DIRECTORY)
        // searchValue
        return

      case 'te-patients':
        navigate(AppRoutingConfig.MY_PATIENTS)
        // searchValue
        return
      case 'so-patients':
        navigate(AppRoutingConfig.MY_PATIENTS)
        dispatch(setSoFromGlobalSearchForPatients({ so_from_global_search_for_patients: true }))
        // searchValue
        return
      case 'so-requests':
        navigate(AppRoutingConfig.MY_REQUESTS)
        dispatch(setSoFromGlobalSearchForRequest({ so_from_global_search_for_request: true }))
        // searchValue
        return
      case 'te-requests':
        navigate(AppRoutingConfig.MY_REQUESTS)
        // searchValue
        return
      default:
        return
    }
  }

  const globalSearchOptions = (list) => {
    return list
      ?.map((item, index) => {
        const createPersonOption = (data, handleClick) =>
          data?.map((i) => ({
            label: (
              <div
                key={i.id}
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                onClick={() => handleClick(i?.user_no ? i?.user_no : i?.id)}
              >
                <ClockCircleOutlined />
                {i?.first_name} {i?.last_name}
              </div>
            ),
            value: `${i?.first_name} ${i?.last_name} - ${i?.id}` // Store first and last name in value for searching
          }))

        const createRequestOption = (data, handleClick) =>
          data?.map((i) => ({
            label: (
              <div
                key={i.id}
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                onClick={() => handleClick(i.id)}
              >
                <ClockCircleOutlined />
                {i?.request_id}
              </div>
            ),
            value: `${i?.request_id} - ${i?.id}` // Store first and last name in value for searching
          }))

        const seeMoreButton = (category) => ({
          label: (
            <Button
              type="link"
              style={{ paddingLeft: '0.5rem', color: 'var(--cyan-blue)', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                handleGlobalNavigate(category)
                setSearchValue(null)
                selectRef.current.blur()
              }}
            >
              {t('CommonUtils.seeMore')}
            </Button>
          ),
          value: `see-more-${category}-${index}`,
          disabled: true
        })

        const clearSearchAndCloseDropdown = () => {
          setSearchValue(null)
          dispatch(clearGlobalSearchList())
        }

        if (item.source_table === 'auth_user') {
          const handleClick = (id) => {
            navigate(AppRoutingConfig.PREVIEW_PROFILE + id)
            clearSearchAndCloseDropdown()
          }
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.physicians')}
              </Typography.Title>
            ),
            options: [...createPersonOption(item?.data, handleClick), seeMoreButton('physicians')],
            key: `physicians-${index}`
          }
        }
        if (item.source_table === 'te_patients') {
          const handleClick = () => {
            navigate(AppRoutingConfig.MY_PATIENTS)
            clearSearchAndCloseDropdown()
          }
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.TeleExpertisePatients')}
              </Typography.Title>
            ),
            options: [...createPersonOption(item?.data, handleClick), seeMoreButton('te-patients')],
            key: `te_patients-${index}`
          }
        }
        if (item.source_table === 'so_patients') {
          const handleClick = () => {
            navigate(AppRoutingConfig.MY_PATIENTS)
            clearSearchAndCloseDropdown()
          }
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.SecondOpinionPatients')}
              </Typography.Title>
            ),
            options: [...createPersonOption(item?.data, handleClick), seeMoreButton('so-patients')],
            key: `so_patients-${index}`
          }
        }
        if (
          item.source_table === 'te_requests' &&
          userDetails?.permissions?.admin_permissions?.['View Request Details']
        ) {
          const handleClick = (id) => {
            navigate(AppRoutingConfig.VIEW_CASE_REQUEST + id)
            clearSearchAndCloseDropdown()
          }
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.teRequest')}
              </Typography.Title>
            ),
            options: [
              ...createRequestOption(item?.data, handleClick),
              seeMoreButton('te-requests')
            ],
            key: `te_requests-${index}`
          }
        }
        if (
          item.source_table === 'so_requests' &&
          userDetails?.permissions?.admin_permissions?.['View Request Details']
        ) {
          const handleClick = (id) => {
            navigate(AppRoutingConfig.VIEW_SO_CASE_REQUEST + id)
            clearSearchAndCloseDropdown()
          }
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.soRequest')}
              </Typography.Title>
            ),
            options: [
              ...createRequestOption(item?.data, handleClick),
              seeMoreButton('so-requests')
            ],
            key: `so_requests-${index}`
          }
        }

        return null
      })
      .filter(Boolean)
  }
  const selectRef = useRef(null) // Ref to the Select component

  const availability_status = {
    available: {
      badge: <Badge status="success" />,
      title: t('CommonUtils.Available')
    },
    unavailable: {
      badge: <Badge status="error" />,
      title: t('CommonUtils.NotAvailable')
    }
  }

  /* on dropdown change */
  const handleLanguageDropdownChange = (value) => {
    const updatedLanguageData = Object.keys(languageData)?.map((item) => {
      if (item === value?.key) {
        return { ...languageData[item], active: true }
      } else {
        return { ...languageData[item], active: false }
      }
    })
    const activeLanguage = updatedLanguageData?.filter((item) => item?.active)
    i18next.changeLanguage(value?.key)
    updateLanguage(activeLanguage[0])
  }
  /* on dropdown change */

  /* dialog dependencies */
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)

  const handleOpenLogoutDialog = () => {
    setLogoutDialogOpen(true)
  }

  const handleCloseLogoutDialog = () => {
    setLogoutDialogOpen(false)
  }

  const handleLogout = () => {
    localStorage.clear()
    navigate(AppRoutingConfig.APP_URL_LOGIN)
    dispatch(AuthService.logout({ payload: {}, showNotification, navigate }))
    dispatch(clearCommonDataDetails())
  }

  const logoutDialogProps = useMemo(
    () => ({
      open: logoutDialogOpen,
      onClose: handleCloseLogoutDialog,
      onLogout: handleLogout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logoutDialogOpen]
  )
  /* dialog dependencies */

  return (
    <div
      className={`navbar-container navbar-wrapper ${isPrimaryLayout ? 'primary-navbar' : 'main-navbar'}`}
    >
      <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
        <Col
          xs={23}
          sm={2}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          xl={5}
          xxl={6}
          className="breadcrumb-column-container"
        >
          <Flex align="center" gap="1rem">
            <BreadCrumbs isPrimaryLayout={isPrimaryLayout} />
          </Flex>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          xl={19}
          xxl={18}
        >
          <Row gutter={[20, 20]} style={{ alignItems: 'center' }}>
            {isPrimaryLayout && (
              <Col xs={4} sm={2} md={1} lg={1} xl={1} xxl={1}>
                <Button
                  shape="circle"
                  style={{ width: 30, aspectRatio: 1 }}
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  onClick={() => {
                    navigate(navigationRoute, {
                      state: {
                        prevPath: [...(location.state?.prevPath || [])].slice(0, -1)
                      }
                    })
                  }}
                />
              </Col>
            )}
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 7, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 8, order: 1 }}
              xl={{ span: 7, order: 1 }}
              xxl={{ span: 7, order: 1 }}
            >
              {selectedRole !== roles.patient && (
                <Select
                  showSearch
                  allowClear
                  value={searchValue}
                  placeholder={
                    <div style={{ color: isPrimaryLayout && 'var(--pure-white)' }}>
                      <SearchOutlined style={{ color: isPrimaryLayout && 'var(--pure-white)' }} />
                      &nbsp; {t('CommonUtils.Search')}
                    </div>
                  }
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  onSearch={handleSearch}
                  onBlur={handleBlur}
                  onClear={() => {
                    dispatch(clearGlobalSearchList())
                  }}
                  options={globalSearchOptions(globalSearchData?.data)}
                  ref={selectRef}
                  filterOption={(input, option) => {
                    const searchText = input.toLowerCase()
                    const matchInValue = option?.value?.toLowerCase().includes(searchText)

                    const matchInFirstName = option?.label?.props?.children?.[1]
                      ?.toLowerCase()
                      .includes(searchText)
                    const matchInLastName = option?.label?.props?.children?.[3]
                      ?.toLowerCase()
                      .includes(searchText)

                    const isSeeMore = option?.value?.startsWith('see-more')

                    return matchInValue || matchInFirstName || matchInLastName || isSeeMore
                  }}
                  style={{
                    width: '100%',
                    backgroundColor: isPrimaryLayout
                      ? 'var(--primary-dark-color)'
                      : 'var(--silver-sand)',
                    color: isPrimaryLayout && 'var(--pure-white) !important',
                    borderRadius: 12
                  }}
                  dropdownStyle={{
                    height: globalSearchData?.loading && globalSearchData?.data?.length > 0 && 250
                  }}
                  className={isPrimaryLayout && 'profile-selection-dropdown-white'}
                  variant="borderless"
                  notFoundContent={
                    globalSearchData?.loading && (
                      <Flex align="center" justify="center" style={{ height: 250 }}>
                        <Spin />
                      </Flex>
                    )
                  }
                />
              )}
            </Col>

            <Col
              xs={{ span: 17, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 10 : 9) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 6, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6, order: 1 }}
              xl={{ span: 5, order: 1 }}
              xxl={{ span: 6, order: 1 }}
            >
              {![roles.superAdmin, roles.patient].includes(selectedRole) &&
                (!getCommunity?.data || getCommunity?.data?.length > 1) && (
                  <Select
                    defaultActiveFirstOption
                    value={activeProfile}
                    options={getCommunity?.data}
                    onMouseEnter={(e) => {
                      e.target.removeAttribute('title')
                    }}
                    style={{
                      width: '100%',
                      backgroundColor: isPrimaryLayout
                        ? 'var(--primary-dark-color)'
                        : 'var(--silver-sand)',
                      color: isPrimaryLayout && 'var(--pure-white) !important',
                      borderRadius: 12
                    }}
                    variant="borderless"
                    optionRender={(option) => {
                      const { data } = option
                      return (
                        <Flex gap="0.5rem">
                          <Flex style={{ minWidth: 30 }} align="center" justify="center">
                            <Avatar
                              icon={<UserOutlined />}
                              size={30}
                              src={data?.community_url || data?.profile_pic}
                            />
                          </Flex>
                          <Flex gap="0.5rem" vertical justify="center">
                            <Typography.Title level={4} style={{ margin: 0 }}>
                              {data?.label}
                            </Typography.Title>
                            {data?.label !== 'Individual' && (
                              <Typography.Paragraph
                                style={{ textTransform: 'capitalize', margin: 0 }}
                              >
                                {data?.community_members?.[0]?.role}
                              </Typography.Paragraph>
                            )}
                          </Flex>
                        </Flex>
                      )
                    }}
                    onChange={(newValue) => {
                      const selectedProfiledVal = getCommunity?.data?.filter(
                        (element) => element.value === newValue
                      )
                      const selectedProfile = selectedProfiledVal[selectedProfiledVal?.length - 1]
                      if (selectedProfile?.label === 'Individual') {
                        dispatch(
                          CommonService.community_revoke_token({
                            payload: {},
                            showNotification,
                            navigate
                          })
                        )
                      } else {
                        dispatch(
                          CommonService.community_create_token({
                            payload: { community_id: selectedProfile?.id },
                            navigate,
                            navigateRoute: AppRoutingConfig.HOME,
                            showNotification
                          })
                        )
                      }
                      dispatch(setActiveProfile(selectedProfile))
                      encryptData('selectedProfile', selectedProfile)
                    }}
                    className={isPrimaryLayout && 'profile-selection-dropdown-white'}
                  />
                )}
            </Col>

            <Col
              xs={{ span: 7, order: 3 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 6) : 6, order: 2 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 4 : 4) : 4, order: 2 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 5) : 4, order: 2 }}
              xl={{ span: 5, order: 2 }}
              xxl={{ span: 5, order: 2 }}
            >
              <Flex align="center" justify="center">
                <Dropdown
                  menu={{
                    items: Object.keys(languageData).map((element) => {
                      return {
                        label: languageData[element]?.label,
                        value: languageData[element]?.value,
                        key: languageData[element]?.value
                      }
                    }),
                    onClick: handleLanguageDropdownChange
                  }}
                  placement="topRight"
                  trigger={['click']}
                >
                  <Button
                    shape="circle"
                    type="text"
                    style={{
                      color: isPrimaryLayout && 'var(--pure-white)'
                    }}
                  >
                    <Flex align="center" gap="0.5rem">
                      <GlobalIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                      {selectedLanguage?.label}
                    </Flex>
                  </Button>
                </Dropdown>
                <Divider
                  type="vertical"
                  style={{
                    height: '40px',
                    backgroundColor: isPrimaryLayout && 'var(--pure-white)'
                  }}
                />
                <Flex gap="0.2rem" align="center" justify="center">
                  {selectedRole === roles.physician && !isPrimaryLayout && (
                    <div
                      className={`tour-container ${!isPrimaryLayout && sidebarCollapsed ? 'tour-container-hidden' : ''}`}
                    >
                      <Tooltip title="App Tutorial">
                        <Button
                          icon={
                            <ReadOutlined
                              style={{
                                fontSize: 18,
                                color: isPrimaryLayout && 'var(--pure-white)'
                              }}
                            />
                          }
                          shape="circle"
                          type="text"
                          style={{ width: 40, aspectRatio: 1 }}
                          onClick={handleOpenSynappTourDialog}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {selectedRole === roles.superAdmin && (
                    <>
                      <div className="broadcast-container" ref={broadCastRef}>
                        <BroadcastWidget
                          showBroadCast={showBroadCast}
                          setShowBroadCast={setShowBroadCast}
                        />
                        <GetCustomTooltipConditionally title="Broadcast" showTooltip={showContent}>
                          <Button
                            icon={<BroadCastIcon color={isPrimaryLayout && 'var(--pure-white)'} />}
                            shape="circle"
                            type="text"
                            style={{ width: 40, aspectRatio: 1 }}
                            onClick={() => {
                              setShowBroadCast((prevState) => !prevState)
                            }}
                          />
                        </GetCustomTooltipConditionally>
                      </div>
                      <Divider
                        type="vertical"
                        style={{
                          height: '40px',
                          backgroundColor: isPrimaryLayout && 'var(--pure-white)'
                        }}
                      />
                    </>
                  )}

                  <div
                    className="notification-container"
                    ref={showContent ? notificationRef : null}
                  >
                    <NotificationWidget
                      isCollapsed={collapsed}
                      setCollapsed={setCollapsed}
                      showContent={showContent}
                      setShowContent={setShowContent}
                    />
                    <GetCustomTooltipConditionally title="Notifications" showTooltip={showContent}>
                      <Button
                        icon={
                          <Badge count={getNotificationsList?.notificationListData?.totalCount}>
                            <NotificationIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                          </Badge>
                        }
                        shape="circle"
                        type="text"
                        style={{ width: 40, aspectRatio: 1 }}
                        onClick={() => {
                          setSidebarCollapsed && !showContent && setSidebarCollapsed(true)
                          setCollapsed((prevState) => !prevState)
                        }}
                      />
                    </GetCustomTooltipConditionally>
                  </div>
                </Flex>
              </Flex>
            </Col>

            <Col
              xs={{ span: !isPrimaryLayout ? 24 : 20, order: 1 }}
              sm={{ span: 9, order: 3 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 6, order: 3 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 5, order: 3 }}
              xl={{ span: 6, order: 3 }}
              xxl={{ span: 5, order: 3 }}
              className="profile-container"
            >
              <Dropdown
                menu={{
                  items: [roles.physician, roles.hcp].includes(selectedRole)
                    ? userDetails?.permissions?.admin_permissions?.['Manage User Settings']
                      ? userDropdownItems
                      : userDropdownItems?.filter((element) => element.key !== '1')
                    : userDropdownItems,
                  selectable: false,
                  onClick: (event) => {
                    const clickedItem = userDropdownItems?.find((item) => item.key === event.key)
                    if (clickedItem.key === '1') {
                      selectedRole === roles.superAdmin
                        ? navigate(AppRoutingConfig.MY_PROFILE)
                        : navigate(AppRoutingConfig.PROFILE_INFORMATION)
                    }
                    if (clickedItem.key === '2') {
                      handleOpenLogoutDialog()
                    }
                  }
                }}
                trigger={['click']}
                placement="bottom"
                arrow={{ pointAtCenter: true }}
              >
                <Flex gap="0.5rem" ref={ref?.ref1} align="center">
                  <div style={{ width: 50 }}>
                    {selectedRole === roles.physician && userDetails?.is_profile_verified ? (
                      <VerifiedBadge size={40} src={userDetails?.profile_pic} />
                    ) : (
                      <Avatar icon={<UserOutlined />} size={40} src={userDetails?.profile_pic} />
                    )}
                  </div>
                  <Flex vertical gap={selectedRole === roles.physician ? 0 : '0.2rem'}>
                    <Typography.Title
                      level={4}
                      style={{ color: isPrimaryLayout && 'var(--pure-white)' }}
                      ellipsis={true}
                    >
                      {userDetails &&
                        `${userDetails?.title || ''} ${userDetails?.first_name} ${userDetails?.last_name}`}
                    </Typography.Title>
                    {[roles.physician, roles.hcp].includes(selectedRole) ? (
                      <Button
                        type="text"
                        style={{
                          padding: 0,
                          height: 20,
                          color: isPrimaryLayout && 'var(--pure-white)'
                        }}
                      >
                        <Flex align="center" gap="0.5rem">
                          {userDetails?.availability_status && (
                            <>
                              {availability_status[userDetails?.availability_status]?.badge}
                              {availability_status[userDetails?.availability_status]?.title}
                            </>
                          )}
                          <ArrowDownIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                        </Flex>
                      </Button>
                    ) : selectedRole === roles.superAdmin ? (
                      <Tag color="blue" style={{ borderRadius: 6, width: 'fit-content' }}>
                        {t('CommonUtils.SuperAdmin')}
                      </Tag>
                    ) : [roles.author, roles.editor].includes(selectedRole) ? (
                      <>
                        <Flex align="center" gap="0.5rem" style={{ width: '100%' }}>
                          <Typography.Paragraph
                            style={{
                              textTransform: 'capitalize',
                              margin: 0,
                              color: isPrimaryLayout && 'var(--pure-white)'
                            }}
                          >
                            {selectedRole} | {selectedProfileDetails?.community_title}
                          </Typography.Paragraph>
                        </Flex>
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Flex>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <SynappTourDialog {...synappTourDialogProps} />
      <LogoutDialog {...logoutDialogProps} />
    </div>
  )
}

const GetCustomTooltipConditionally = ({ children, showTooltip, title }) => {
  return showTooltip ? <Tooltip title={title}>{children}</Tooltip> : children
}

export default Navbar
