import { createSlice } from '@reduxjs/toolkit'
import {
  get_physician_dashboard,
  get_physician_demographics,
  get_total_physician_revenue_graph,
  get_physician_total_requests_received_graph
} from '../../redux/services/dashboardService'
import { translate } from 'i18n/i18n'
import { cloneDeep } from 'lodash'
import { month_labels } from 'constants'

const CYAN = 'rgba(3, 139, 181, 1)'
const PURPLE = 'rgba(91, 115, 232, 1)'
const BAR_THICKNESS = 80

const commonChartData = {
  labels: month_labels,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  }
}

const chartDataSets = {
  TE: [
    {
      label: 'Tele Expertise',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: CYAN,
      backgroundColor: CYAN,
      maxBarThickness: BAR_THICKNESS
    }
  ],
  SO: [
    {
      label: 'Second Opinion',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: PURPLE,
      backgroundColor: PURPLE,
      maxBarThickness: BAR_THICKNESS
    }
  ]
}

const physicianCardsData = {
  te: [
    {
      id: 1,
      title: 'TelexpertiseRequestsReceived',
      value: 0
    },
    {
      id: 2,
      title: 'RequestsOnHold',
      value: 0
    },
    {
      id: 3,
      title: 'MeanTimeToAnswerRequest',
      value: '0 hours',
      percent: 0,
      isIncreased: true,
      description: translate('Dashboard.FasterSinceLastMonth')
    },
    {
      id: 4,
      title: 'CompletedRequests',
      value: 0
    },
    {
      id: 5,
      title: 'TelexpertiseRevenue',
      amount: 0.0,
      currency: '€'
    }
  ],
  so: [
    {
      id: 1,
      title: 'SecondOpinionRequestsReceived',
      value: 0
    },
    {
      id: 2,
      title: 'RequestsOnHold',
      value: 0
    },
    {
      id: 3,
      title: 'MeanTimeToAnswerRequest',
      value: '0 hours',
      percent: 0,
      isIncreased: true,
      description: translate('Dashboard.FasterSinceLastMonth')
    },
    {
      id: 4,
      title: 'CompletedRequests',
      value: 0
    },
    {
      id: 5,
      title: 'SecondOpinionRevenue',
      amount: 0.0,
      currency: '€'
    },
    {
      id: 6,
      title: 'NumberOfVideoConsumption',
      value: 0
    }
  ]
}

const initialState = {
  physicianDashboard: {
    loading: false,
    cardsData: physicianCardsData['te'],
    topFiveData: null,
    noOfRequests: null
  },
  getPhysicianTotalRevenueGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: chartDataSets['TE'],
      options: commonChartData.options
    }
  },
  getPhysicianDemographics: {
    loading: false,
    data: null
  },
  getPhysicianTotalRequestsReceivedGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: chartDataSets['TE'],
      options: commonChartData.options
    }
  }
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get physician dashboard
    builder.addCase(get_physician_dashboard.pending, (state) => {
      state.physicianDashboard.loading = true
    })
    builder.addCase(get_physician_dashboard.fulfilled, (state, action) => {
      const response_for_dashboard_cards = action.payload?.response?.upper
      const response_for_your_top_five = action.payload?.response?.top_five
      const response_no_of_requests = action.payload?.response?.no_of_request

      state.physicianDashboard.loading = false
      if (!response_for_dashboard_cards) return

      const {
        total_revenue,
        received_request_count,
        onhold_request_count,
        improvement_percentage,
        mean_time_to_answer,
        asnwered_request_count,
        video_request_count
      } = response_for_dashboard_cards
      state.physicianDashboard.cardsData = physicianCardsData[action.payload.requestPayload.type]
      let default_data = cloneDeep(state.physicianDashboard.cardsData)

      const getCard = (data, id) => data?.find((element) => element.id === id)

      const requestsReceivedCard = getCard(default_data, 1)
      const requestsOnHoldCard = getCard(default_data, 2)
      const timeToAnswerRequestCard = getCard(default_data, 3)
      const completedRequestCard = getCard(default_data, 4)
      const revenueCard = getCard(default_data, 5)
      const videoRequestCard = getCard(default_data, 6)

      if (revenueCard) revenueCard.amount = Number(total_revenue)?.toFixed(2) || '0.00'
      if (requestsReceivedCard) requestsReceivedCard.value = received_request_count || 0
      if (requestsOnHoldCard) requestsOnHoldCard.value = onhold_request_count || 0
      if (completedRequestCard) completedRequestCard.value = asnwered_request_count || 0
      if (timeToAnswerRequestCard) {
        timeToAnswerRequestCard.isIncreased =
          improvement_percentage && Number(improvement_percentage) >= 0
        timeToAnswerRequestCard.percent =
          improvement_percentage && Number(improvement_percentage).toFixed(2)
        timeToAnswerRequestCard.value = `${Number(mean_time_to_answer).toFixed(2)} hours`
      }
      if (videoRequestCard) videoRequestCard.value = video_request_count || 0

      state.physicianDashboard.cardsData = default_data
      state.physicianDashboard.topFiveData = response_for_your_top_five
      state.physicianDashboard.noOfRequests = response_no_of_requests
    })
    builder.addCase(get_physician_dashboard.rejected, (state) => {
      state.physicianDashboard.loading = false
    })

    // get physician total revenue graph
    builder.addCase(get_total_physician_revenue_graph.pending, (state) => {
      state.getPhysicianTotalRevenueGraph.loading = true
    })
    builder.addCase(get_total_physician_revenue_graph.fulfilled, (state, action) => {
      state.getPhysicianTotalRevenueGraph.data.datasets = cloneDeep(
        chartDataSets[action.payload.requestPayload.flag]
      )

      const createMonthlyDataArray = (data) => {
        const monthlyDataArr = Array(12).fill(0)
        data[0]?.month_wise?.forEach(({ month, revenue }) => {
          monthlyDataArr[month - 1] = revenue
        })
        return monthlyDataArr
      }

      const newDataArr = createMonthlyDataArray(action.payload.response)

      state.getPhysicianTotalRevenueGraph.data.datasets[0].data = newDataArr
      state.getPhysicianTotalRevenueGraph.loading = false
    })
    builder.addCase(get_total_physician_revenue_graph.rejected, (state) => {
      state.getPhysicianTotalRevenueGraph.loading = false
    })

    // get physician user demographics
    builder.addCase(get_physician_demographics.pending, (state) => {
      state.getPhysicianDemographics.loading = true
    })
    builder.addCase(get_physician_demographics.fulfilled, (state, action) => {
      state.getPhysicianDemographics.data = action?.payload?.response
      state.getPhysicianDemographics.loading = false
    })
    builder.addCase(get_physician_demographics.rejected, (state) => {
      state.getPhysicianDemographics.loading = false
    })

    // get physician total requests received graph
    builder.addCase(get_physician_total_requests_received_graph.pending, (state) => {
      state.getPhysicianTotalRequestsReceivedGraph.loading = true
    })
    builder.addCase(get_physician_total_requests_received_graph.fulfilled, (state, action) => {
      let modified_arr = []
      state.getPhysicianTotalRequestsReceivedGraph.data.datasets = cloneDeep(
        chartDataSets[action.payload.requestPayload.flag]
      )

      modified_arr = action?.payload?.response?.map((element) => {
        const month = new Date(element?.month_group).toLocaleString('en-us', {
          month: 'short',
          year: 'numeric'
        })
        return {
          month: month,
          count: element?.count
        }
      })

      state.getPhysicianTotalRequestsReceivedGraph.data.labels = modified_arr.map(
        (item) => item.month
      )
      state.getPhysicianTotalRequestsReceivedGraph.data.datasets[0].data = modified_arr.map(
        (item) => item.count
      )
      state.getPhysicianTotalRequestsReceivedGraph.loading = false
    })
    builder.addCase(get_physician_total_requests_received_graph.rejected, (state) => {
      state.getPhysicianTotalRequestsReceivedGraph.loading = false
    })
  }
})

export default dashboardSlice.reducer
