import { Badge, Flex, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  return (
    <Flex vertical gap={'1rem'} justify="center">
      <Flex align="center" gap={'0.5rem'}>
        <Badge color="var(--deep-navy-blue)" />
        <Typography.Title level={2} style={{ margin: 0 }}>
          {t('AboutUs.PlatformVersion')}
        </Typography.Title>
        <Typography.Title level={2} style={{ margin: 0 }}>
          1.0
        </Typography.Title>
      </Flex>
      <Flex align="baseline" gap={'0.5rem'}>
        <Badge color="var(--deep-navy-blue)" />
        <Typography.Title level={2} style={{ margin: 0 }}>
          {t('AboutUs.Founders')}
        </Typography.Title>
        <Flex vertical>
          <Typography.Title level={2} style={{ margin: 0, fontWeight: 500 }}>
            Imad Bousaid
          </Typography.Title>
          <Typography.Title level={2} style={{ margin: 0, fontWeight: 500 }}>
            Habib Habchi
          </Typography.Title>
        </Flex>
      </Flex>
      <Flex vertical gap={'0.5rem'}>
        <Flex align="center" gap={'0.5rem'}>
          <Badge color="var(--deep-navy-blue)" />
          <Typography.Title level={2} style={{ margin: 0 }}>
            {t('AboutUs.AboutSynapp')}
          </Typography.Title>
        </Flex>
        <Flex gap={'0.5rem'} style={{ paddingLeft: '2rem' }}>
          <Badge color="var(--deep-navy-blue)" />
          <Typography.Paragraph style={{ margin: 0, fontSize: '1rem', fontWeight: 500 }}>
            {t('AboutUs.AboutSynappText')}
          </Typography.Paragraph>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AboutUs
