import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { AppointmentsIcon, HomeIcon, MyLibraryIcon, MyRequestsIcon, SettingsIcon } from 'assets/svg'

export const patientSidebarUtils = (t, ref2, ref3, ref4, ref5, permissions) => {
  let menuGroup = [
    {
      id: 'overview',
      title: t('AppModuleNames.Overview'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    {
      id: 'home',
      title: t('AppModuleNames.Home'),
      icon: HomeIcon,
      url: AppRoutingConfig.HOME
    },
    {
      id: 'appointments',
      title: t('AppModuleNames.Appointments'),
      icon: AppointmentsIcon,
      url: AppRoutingConfig.APPOINTMENTS,
      ref: ref2
    },
    {
      id: 'my-requests',
      title: t('AppModuleNames.MyRequests'),
      icon: MyRequestsIcon,
      url: AppRoutingConfig.MY_REQUESTS,
      ref: ref3
    },
    permissions?.['Create Request'] && {
      id: 'my-library',
      title: t('AppModuleNames.MyLibrary'),
      icon: MyLibraryIcon,
      url: AppRoutingConfig.MY_LIBRARY,
      ref: ref4
    },
    permissions?.['Manage User Settings'] && {
      id: 'account',
      title: t('AppModuleNames.Account'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    permissions?.['Manage User Settings'] && {
      id: 'settings',
      title: t('AppModuleNames.Settings'),
      icon: SettingsIcon,
      url: AppRoutingConfig.PROFILE_INFORMATION,
      ref: ref5
    }
  ]

  return menuGroup
}
