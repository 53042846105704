import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get physicians list
export const get_physicians_list = createAsyncThunk(
  'physicians/get_physicians_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/setting/member/physician-list`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// deactivate physician
export const deactivate_physician = createAsyncThunk(
  'physicians/deactivate_physician',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/setting/member/status-trigger`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData(response)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update physician plan
export const update_physician_plan = createAsyncThunk(
  'physicians/update_physician_plan',
  async (
    { payload, showNotification, handleCloseDialog, handleGetPhysicianData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `v1/admin/setting/member/user-recommended-plan`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetPhysicianData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Change physician password
export const change_physician_password = createAsyncThunk(
  'physicians/change_physician_password',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/setting/member/change-password`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
