import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// Define the async thunk using createAsyncThunk
export const admin_get_finance_list = createAsyncThunk(
  'finance/admin_get_finance_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/financial-report?filter=${payload.filter}&search=${payload.search}${payload.month && `&month=${payload.month}`}${payload.year && `&year=${payload.year}`}&page=${payload.page}&pageSize=${payload.pageSize}`
      )

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        if (response?.data?.length > 0) {
          displayMultipleNotifications(response.data, showNotification)
        }
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Define the async thunk using createAsyncThunk
export const admin_get_finance_list_community = createAsyncThunk(
  'finance/admin_get_finance_list_community',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/financial-report?filter=${payload.filter}&search=${payload.search}${payload.month && `&month=${payload.month}`}${payload.year && `&year=${payload.year}`}&page=${payload.page}&pageSize=${payload.pageSize}`
      )

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        if (response?.data?.length > 0) {
          displayMultipleNotifications(response.data, showNotification)
        }
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Define the async thunk using createAsyncThunk
export const update_finance_status = createAsyncThunk(
  'finance/update_finance_status',
  async ({ payload, showNotification, setOpen }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/financial-report/update`, payload)

      if (response.status === 0) {
        setOpen({})
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        setOpen({})
        if (response?.data?.length > 0) {
          displayMultipleNotifications(response.data, showNotification)
        }
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
