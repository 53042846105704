import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import CaseLayout from 'components/layouts/case-layout'
import UserDetailsProvider from 'context/UserDetailsProvider'
import * as lazy from './lazyComponents'
import RequireAuth from 'app/requireAuth'
import { roles } from '../../constants'

export const common_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: (
      <UserDetailsProvider>
        <CaseLayout />
      </UserDetailsProvider>
    ),
    children: [
      {
        path: AppRoutingConfig.VIEW_PHYSICIAN_PROFILE,
        element: <lazy.PhysicianViewProfile />
      },
      {
        path: AppRoutingConfig.VIEW_COMMUNITY_PROFILE,
        element: <lazy.CommunityViewProfile />
      },
      {
        path: AppRoutingConfig.VIEW_PATIENT_PROFILE,
        element: <lazy.PatientViewProfile />
      },
      {
        path: AppRoutingConfig.VIEW_ACTIVITY_LOGS,
        element: <lazy.ViewActivityLogs />
      }
    ]
  },

  // Document upload routes
  {
    path: AppRoutingConfig.DOCUMENT_UPLOAD,
    element: <lazy.DocumentUploader />
  },
  {
    path: AppRoutingConfig.DOCUMENT_SO_UPLOAD,
    element: <lazy.SODocumentUploader />
  },
  {
    path: AppRoutingConfig.SO_LATER_UPLOAD,
    element: <lazy.DocumentSoUploader />
  },

  // Document upload routes

  {
    path: AppRoutingConfig.CASE_PAYMENT_CONFIRMATION,
    element: <lazy.PaymentConfirmaiton />
  },
  {
    path: AppRoutingConfig.VIEW_PROFILE,
    element: (
      <UserDetailsProvider>
        <lazy.ViewProfile />
      </UserDetailsProvider>
    )
  },
  {
    path: AppRoutingConfig.VIEW_COMMUNITY,
    element: (
      <UserDetailsProvider>
        <lazy.ViewCommunity />
      </UserDetailsProvider>
    )
  },
  {
    path: AppRoutingConfig.VIEW_CASE_REQUEST,
    element: (
      <UserDetailsProvider>
        <RequireAuth
          allowedPermissions={[
            {
              role: roles.physician,
              permission: 'View Request Details'
            },
            {
              role: roles.hcp,
              permission: 'View Request Details'
            },
            {
              role: roles.superAdmin,
              permission: 'View Request Details'
            },
            {
              role: roles.patient,
              permission: 'View Request Details'
            },
            {
              role: roles.author,
              permission: 'View Request Details'
            },
            {
              role: roles.editor,
              permission: 'View Request Details'
            }
          ]}
        >
          <CaseLayout />
        </RequireAuth>
      </UserDetailsProvider>
    ),
    children: [
      {
        path: AppRoutingConfig.VIEW_CASE_REQUEST_BY_ID,
        element: <lazy.ViewCase />
      }
    ]
  },
  {
    path: AppRoutingConfig.VIEW_SO_CASE_REQUEST,
    element: (
      <RequireAuth
        allowedPermissions={[
          {
            role: roles.physician,
            permission: 'View Request Details'
          },
          {
            role: roles.hcp,
            permission: 'View Request Details'
          },
          {
            role: roles.superAdmin,
            permission: 'View Request Details'
          },
          {
            role: roles.patient,
            permission: 'View Request Details'
          },
          {
            role: roles.author,
            permission: 'View Request Details'
          },
          {
            role: roles.editor,
            permission: 'View Request Details'
          }
        ]}
      >
        <CaseLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: AppRoutingConfig.VIEW_SO_CASE_REQUEST_BY_ID,
        element: <lazy.ViewSOCase />
      }
    ]
  }
]
