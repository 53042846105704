import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import {
  DashboardIcon,
  DirectoryIcon,
  HomeIcon,
  MyCommunityIcon,
  MyPatientsIcon,
  MyRequestsIcon,
  SettingsIcon
} from 'assets/svg'

export const authorEditorSidebarUtils = (t, permissions) => {
  let menuGroup = [
    {
      id: 'overview',
      title: t('AppModuleNames.Overview'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    {
      id: 'home',
      title: t('AppModuleNames.Home'),
      icon: HomeIcon,
      url: AppRoutingConfig.HOME
    },
    permissions?.['Community - Access Dashboard'] && {
      id: 'dashboard',
      title: t('AppModuleNames.Dashboard'),
      icon: DashboardIcon,
      url: AppRoutingConfig.DASHBOARD
    },
    {
      id: 'my-patients',
      title: t('AppModuleNames.AllPatients'),
      icon: MyPatientsIcon,
      url: AppRoutingConfig.MY_PATIENTS
    },
    permissions?.['View All Request'] && {
      id: 'my-requests',
      title: t('AppModuleNames.AllRequests'),
      icon: MyRequestsIcon,
      url: AppRoutingConfig.MY_REQUESTS
    },
    {
      id: 'my-community',
      title: t('AppModuleNames.MyCommunity'),
      icon: MyCommunityIcon,
      url: AppRoutingConfig.MY_COMMUNITY
    },
    {
      id: 'directory',
      title: t('AppModuleNames.Directory'),
      icon: DirectoryIcon,
      url: AppRoutingConfig.DIRECTORY
    },
    {
      id: 'account',
      title: t('AppModuleNames.Account'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    {
      id: 'settings',
      title: t('AppModuleNames.Settings'),
      icon: SettingsIcon,
      url: AppRoutingConfig.PROFILE_INFORMATION
    }
  ]

  return menuGroup
}
