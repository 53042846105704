import { roles } from '../../constants'

export class AppPermissionsConfig {
  static APP_USER_ROLE_KEY = 'role'
  static APP_USER_PERMISSIONS_OBJ_KEY = 'admin_permissions'

  static getAdminPermissionsObjFromArr = (userDetails, selectedRole) => {
    const slug = this.getRoleSlugs(userDetails, selectedRole)
    const { admin_user: adminRoles = [], package: packagePermissions = [] } =
      userDetails?.activityDetail || {}

    const result = {
      role: '',
      admin_permissions: {},
      package_permissions: {}
    }

    adminRoles.forEach(({ name, has_permission, sub_type }) => {
      if (sub_type === slug) {
        result.role = sub_type
        result.admin_permissions[String(name).trim()] = has_permission
      }
    })

    packagePermissions.forEach(({ name, has_permission }) => {
      result.package_permissions[String(name).trim()] = has_permission
    })

    return result
  }

  static getRoleSlugs = (userDetails, selectedRole) => {
    const roleMapping = {
      [roles.physician]: userDetails?.is_profile_verified ? 'USER_VALIDATE' : 'USER_NOT_VALIDATE',
      [roles.hcp]: userDetails?.is_profile_verified ? 'USER_VALIDATE' : 'USER_NOT_VALIDATE',
      [roles.patient]: 'USER_PATIENT',
      [roles.superAdmin]: 'ADMIN_SYNAPP',
      [roles.author]: 'ADMIN_AUTHOR',
      [roles.editor]: 'ADMIN_EDITOR'
    }
    return roleMapping[selectedRole] ?? null
  }
}
