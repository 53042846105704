import axios from 'axios'
import { BASE_URL } from '../constants'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

const axiosInstance = axios.create({
  baseURL: window.location.hostname === 'localhost' ? BASE_URL : process.env.REACT_APP_BACKEND_URL
})

axiosInstance.defaults.withCredentials = true

/* Add a request interceptor */
const requestInterceptor = (config) => {
  config.data = {
    payloadData: {
      requestData: config.data,
      i18n: localStorage.getItem('i18nextLng')
    }
  }

  if (config.method === 'get') {
    config.headers = {
      ...config.headers,
      i18n: localStorage.getItem('i18nextLng')
    }
  }
  return config
}

axiosInstance.interceptors.request.use(requestInterceptor)
/* Add a request interceptor */

/* Add a response interceptor */
const responseInterceptor = (responseData) => {
  if (responseData?.data?.payloadResponse?.sc === 401) {
    // TODO : NAVIGATE TO LOGIN WITHOUT REFRESHING PAGE
    window.location.replace(AppRoutingConfig.APP_URL_LOGIN)
    localStorage.clear()
  }
  let data =
    responseData?.data?.res?.payloadResponse?.data ?? responseData?.data?.payloadResponse?.data
  let status = responseData?.data?.res?.rc ?? responseData?.data?.rc
  let message = responseData?.data?.payloadResponse?.message
  return { data, status, message }
}

axiosInstance.interceptors.response.use(responseInterceptor)
/* Add a response interceptor */

export default axiosInstance
