import { createSlice } from '@reduxjs/toolkit'
import {
  get_cities,
  search_establishments,
  get_profession,
  get_specialities,
  get_sub_specialities,
  get_expertise,
  mark_tour_completed,
  get_languages
} from '../services/appService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    tourOpen: false,
    cities: [],
    citiesLoading: false,
    citiesPagination: null,
    professions: [],
    specialities: [],
    establishments: [],
    subSpecialities: [],
    expertises: [],
    loading: false,
    viewProfile: {
      isOpen: false,
      viewId: null
    },
    viewCommnunity: {
      isOpen: false,
      viewId: null
    },
    markTourCompleted: {
      loading: false
    }
  },
  reducers: {
    openTourModel: (state) => {
      state.tourOpen = true
    },
    closeTourModel: (state) => {
      state.tourOpen = false
    },
    openProfileViewDialog: (state, action) => {
      state.viewProfile.isOpen = true
      state.viewProfile.viewId = action.payload
    },
    closeProfileViewDialog: (state) => {
      state.viewProfile = {
        isOpen: false,
        viewId: null
      }
    },
    openCommunityViewDialog: (state, action) => {
      state.viewCommnunity.isOpen = true
      state.viewCommnunity.viewId = action.payload
    },
    closeCommunityViewDialog: (state) => {
      state.viewCommnunity = {
        isOpen: false,
        viewId: null
      }
    },
    clearCitiesArr: (state) => {
      state.cities = []
    },
    clearSpeciality: (state) => {
      state.specialities = []
    }
  },
  extraReducers: (builder) => {
    // Get Cities
    builder.addCase(get_cities.pending, (state) => {
      state.loading = true
      state.citiesLoading = true
    })
    builder.addCase(get_cities.fulfilled, (state, action) => {
      state.loading = false
      state.citiesLoading = false
      state.cities = [
        ...(state.cities || []),
        ...(action.payload?.response?.data?.map((city) => ({
          label: city.name,
          value: city.name,
          ...city
        })) || [])
      ]
      state.citiesPagination = action?.payload?.response?.pagination
    })
    builder.addCase(get_cities.rejected, (state) => {
      state.loading = false
      state.citiesLoading = false
    })
    // get searchEstablishments
    builder.addCase(search_establishments.pending, (state) => {
      state.loading = true
    })
    builder.addCase(search_establishments.fulfilled, (state, action) => {
      state.loading = false
      state.establishments = action.payload?.response?.results?.map((institution) => {
        return { value: institution.name, label: institution.name, ...institution }
      })
    })
    builder.addCase(search_establishments.rejected, (state) => {
      state.loading = false
    })
    // get Profession
    builder.addCase(get_profession.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_profession.fulfilled, (state, action) => {
      state.loading = false
      state.professions = action.payload?.response?.data.map((profession) => {
        return { value: profession.value, label: profession.display_name, ...profession }
      })
    })
    builder.addCase(get_profession.rejected, (state) => {
      state.loading = false
    })
    // get Specialities
    builder.addCase(get_specialities.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_specialities.fulfilled, (state, action) => {
      state.loading = false
      state.specialities = action.payload?.response?.data?.map((speciality) => {
        return { value: speciality.value, label: speciality.display_name, ...speciality }
      })
    })
    builder.addCase(get_specialities.rejected, (state) => {
      state.loading = false
    })
    // get SubSpecialities
    builder.addCase(get_sub_specialities.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_sub_specialities.fulfilled, (state, action) => {
      state.loading = false
      state.subSpecialities = action.payload?.response?.data?.map((speciality) => {
        return { value: speciality.value, label: speciality.display_name, ...speciality }
      })
    })
    builder.addCase(get_sub_specialities.rejected, (state) => {
      state.loading = false
    })
    // get Expertise
    builder.addCase(get_expertise.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_expertise.fulfilled, (state, action) => {
      state.loading = false
      state.expertises = action.payload?.response?.data?.map((expertise) => {
        return { value: expertise.value, label: expertise.display_name, ...expertise }
      })
    })
    builder.addCase(get_expertise.rejected, (state) => {
      state.loading = false
    })

    // mark tour completed
    builder.addCase(mark_tour_completed.pending, (state) => {
      state.markTourCompleted.loading = true
    })
    builder.addCase(mark_tour_completed.fulfilled, (state) => {
      state.markTourCompleted.loading = false

      const user_data = decryptData('user')
      const modified_user_data = { ...user_data }
      if (modified_user_data) modified_user_data.is_tour_completed = true
      encryptData('user', modified_user_data)
    })
    builder.addCase(mark_tour_completed.rejected, (state) => {
      state.markTourCompleted.loading = false
    })

    // Get Languages
    builder.addCase(get_languages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_languages.fulfilled, (state, action) => {
      state.loading = false
      state.languages = action.payload?.response?.data?.map((language) => ({
        label: language.display_name,
        value: language.value,
        ...language
      }))
    })
    builder.addCase(get_languages.rejected, (state) => {
      state.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  openTourModel,
  closeTourModel,
  openProfileViewDialog,
  closeProfileViewDialog,
  openCommunityViewDialog,
  closeCommunityViewDialog,
  clearCitiesArr,
  clearSpeciality
} = appSlice.actions

export default appSlice.reducer
