import { useTourComponentRefs } from 'context/TourRefsProvider'
import TourTitle from './tour-title'
import { Tour } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeTourModel } from '../../../redux/reducer/appReducer'
import { AppService } from '../../../redux/services'
import { useEffect, useState } from 'react'
import { useUserDetails } from 'context/UserDetailsProvider'
import './SynappTour.scss'

const SynappTour = () => {
  const { ref1, ref2, ref3, ref4, ref5, ref6 } = useTourComponentRefs()
  const open = useSelector((state) => state.app.tourOpen)
  const { endLogin } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { userDetails } = useUserDetails()

  const [steps, setSteps] = useState([])

  useEffect(() => {
    const initialSteps = [
      {
        title: <TourTitle title={t('productTour.welcome.title')} />,
        description: t('productTour.welcome.description'),
        target: () => ref1.current,
        permission: null
      },
      {
        title: <TourTitle title={t('productTour.dashboard.title')} />,
        description: t('productTour.dashboard.description'),
        placement: 'right',
        target: () => ref2.current,
        permission: 'Access Personal Dashboard'
      },
      {
        title: <TourTitle title={t('productTour.myRequest.title')} />,
        description: t('productTour.myRequest.description'),
        placement: 'right',
        target: () => ref3.current,
        permission: null
      },
      {
        title: <TourTitle title={t('productTour.myCommunity.title')} />,
        description: t('productTour.myCommunity.description'),
        placement: 'right',
        target: () => ref4.current,
        permission: null
      },
      {
        title: <TourTitle title={t('productTour.myLibrary.title')} />,
        description: t('productTour.myLibrary.description'),
        placement: 'right',
        target: () => ref5.current,
        permission: null
      },
      {
        title: <TourTitle title={t('productTour.settings.title')} />,
        description: t('productTour.settings.description'),
        placement: 'right',
        target: () => ref6.current,
        permission: 'Manage User Settings'
      }
    ]
    // Filter steps based on user permissions
    const filteredSteps = initialSteps.filter((step) => {
      if (step.permission === null) return true
      return (
        userDetails?.permissions?.admin_permissions?.[step.permission] ||
        endLogin?.data?.permissions?.admin_permissions?.[step.permission]
      )
    })

    setSteps(filteredSteps)

    return () => {
      setSteps(initialSteps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, t, endLogin?.data])

  return (
    <div className="synapp-tour-container">
      {steps.length > 0 && (
        <Tour
          key={steps.length}
          disabledInteraction
          open={open}
          onClose={() => {
            dispatch(closeTourModel())
            dispatch(AppService.mark_tour_completed({ payload: {} }))
          }}
          steps={steps}
          rootClassName="synapp-product-container"
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} of {total}
            </span>
          )}
        />
      )}
    </div>
  )
}

export default SynappTour
