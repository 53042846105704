import { createSlice } from '@reduxjs/toolkit'

import { month_labels } from 'constants'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { week_labels } from 'constants'
import {
  get_daily_active_users,
  get_dashboard_count,
  get_members_subscribed_graph,
  get_patient_demographics,
  get_physician_demographics,
  get_requests_counts,
  get_total_communities_created_graph,
  get_total_requests_accepted,
  get_total_requests_created,
  get_total_revenue_graph,
  search_community,
  search_physician
} from '../services/synappAdminDashboardService'
import { cloneDeep } from 'lodash'

const CYAN = 'rgba(3, 139, 181, 1)'
const PURPLE = 'rgba(91, 115, 232, 1)'
const BAR_THICKNESS = 80

const commonChartData = {
  labels: month_labels,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  }
}

const scalesWithStepSize01 = {
  y: {
    ticks: {
      stepSize: 1
    }
  }
}

const initialState = {
  synappAdminDashboard: {
    loading: false,
    data: [
      {
        id: 'community_count',
        title: 'NumberOfCommunities',
        value: 0,
        category: ['general']
      },
      {
        id: 'members_count',
        title: 'NumberOfMembers',
        value: 0,
        category: ['general']
      },
      {
        id: 'video_consultation_count',
        title: 'TotalVideoConsultation',
        value: 0,
        category: ['general', 'user', 'community']
      },
      {
        id: 'pending_validation_count',
        title: 'ProfileValidationRequest',
        value: 0,
        navigateTo: AppRoutingConfig.PROFILE_VALIDATION_REQUEST,
        category: ['general']
      },
      {
        id: 'te_sent_count',
        title: 'TeleExpertiseRequestsSent',
        value: 0,
        category: ['user']
      },
      {
        id: 'te_received_count',
        title: 'TeleExpertiseRequestsReceived',
        value: 0,
        category: ['user', 'community']
      },
      {
        id: 'te_request_count',
        title: 'TelexpertiseCases',
        value: 0,
        category: ['general']
      },
      {
        id: 'so_received_count',
        title: 'SecondOpinionRequestsReceived',
        value: 0,
        category: []
      },
      {
        id: 'so_request_count',
        title: 'SecondOpinionCases',
        value: 0,
        category: ['general', 'user', 'community']
      },
      {
        id: 'te_revenue',
        title: 'TelexpertiseTotalRevenue',
        value: 0,
        category: ['user', 'general', 'community']
      },
      {
        id: 'so_revenue',
        title: 'SecondOpinionTotalRevenue',
        value: 0,
        category: ['user', 'general', 'community']
      },
      {
        id: 'country',
        title: 'Country',
        value: 0,
        category: ['user']
      },
      {
        id: 'part_of_total_community',
        title: 'Communities',
        value: 0,
        category: ['user']
      },
      {
        id: 'community',
        title: 'Community',
        value: 0,
        category: ['community']
      },
      {
        id: 'members',
        title: 'Members',
        value: 0,
        category: ['community']
      }
    ]
  },
  getDailyActiveUsers: {
    loading: false,
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: { ...commonChartData.options, scale: scalesWithStepSize01 }
    }
  },
  getTotalRevenueGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: commonChartData.options
    }
  },
  getMembersSubscribedGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Members Subscribed',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: { ...commonChartData.options, scale: scalesWithStepSize01 }
    }
  },
  getTotalCommunitiesCreatedGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Total Communities Created',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: { ...commonChartData.options, scale: scalesWithStepSize01 }
    }
  },
  getPatientDemographics: {
    loading: false,
    data: null
  },
  getPhysicianDemographics: {
    loading: false,
    data: null
  },
  getSearchPhysicianList: {
    loading: false,
    data: null
  },
  getSearchCommunityList: {
    loading: false,
    data: null
  },
  getTotalRequestsCreated: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Total Requests Created',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: { ...commonChartData.options, scale: scalesWithStepSize01 }
    }
  },
  getTotalRequestsAccepted: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN,
          maxBarThickness: BAR_THICKNESS
        }
      ],
      options: { ...commonChartData.options, scale: scalesWithStepSize01 }
    }
  },
  getRequestsCounts: {
    loading: false,
    data: null
  }
}

export const synappAdminDashboardSlice = createSlice({
  name: 'synappAdminDashboard',
  initialState,
  reducers: {
    clearPhysiciansData: (state) => {
      state.getSearchPhysicianList.data = []
    },
    clearCommunitiesData: (state) => {
      state.getSearchCommunityList.data = []
    }
  },
  extraReducers: (builder) => {
    // get total revenue graph
    builder.addCase(get_dashboard_count.pending, (state) => {
      state.synappAdminDashboard.loading = true
    })
    builder.addCase(get_dashboard_count.fulfilled, (state, action) => {
      const {
        community_count = 0,
        members_count = 0,
        pending_validation_count,
        so_request_count = 0,
        so_revenue = 0,
        te_request_count = 0,
        te_revenue = 0,
        video_consultation_count = 0,
        te_sent_count = 0,
        te_received_count = 0,
        so_received_count = 0,
        part_of_total_community = 0
      } = action?.payload?.response || {}
      const getCard = (data, id) => data?.find((element) => element.id === id)
      const default_data = cloneDeep(state.synappAdminDashboard.data)

      const community_card = getCard(default_data, 'community_count')
      const members_card = getCard(default_data, 'members_count')
      const pending_validation_card = getCard(default_data, 'pending_validation_count')
      const so_request_card = getCard(default_data, 'so_request_count')
      const te_request_card = getCard(default_data, 'te_request_count')
      const so_revenue_card = getCard(default_data, 'so_revenue')
      const te_revenue_card = getCard(default_data, 'te_revenue')
      const video_consultation_card = getCard(default_data, 'video_consultation_count')
      const te_sent_card = getCard(default_data, 'te_sent_count')
      const te_received_card = getCard(default_data, 'te_received_count')
      const so_received_card = getCard(default_data, 'so_received_count')
      const part_of_total_community_card = getCard(default_data, 'part_of_total_community')

      if (community_card) community_card.value = community_count
      if (members_card) members_card.value = members_count
      if (pending_validation_card) pending_validation_card.value = pending_validation_count
      if (so_request_card) so_request_card.value = so_request_count
      if (te_request_card) te_request_card.value = te_request_count
      if (so_revenue_card) so_revenue_card.value = `€ ${so_revenue}`
      if (te_revenue_card) te_revenue_card.value = `€ ${te_revenue}`
      if (video_consultation_card) video_consultation_card.value = video_consultation_count
      if (te_sent_card) te_sent_card.value = te_sent_count
      if (te_received_card) te_received_card.value = te_received_count
      if (so_received_card) so_received_card.value = so_received_count
      if (part_of_total_community_card) part_of_total_community_card.value = part_of_total_community

      state.synappAdminDashboard.data = default_data
      state.synappAdminDashboard.loading = false
    })
    builder.addCase(get_dashboard_count.rejected, (state) => {
      state.synappAdminDashboard.loading = false
    })

    // get daily active users
    builder.addCase(get_daily_active_users.pending, (state) => {
      state.getDailyActiveUsers.loading = true
    })
    builder.addCase(get_daily_active_users.fulfilled, (state, action) => {
      state.getDailyActiveUsers.loading = false
      let modified_arr = []
      const filterValue = action?.payload?.requestPayload?.filter?.toLowerCase()
      state.getDailyActiveUsers.data.datasets[0].label =
        filterValue?.[0]?.toUpperCase() + filterValue?.slice(1)

      let weeklyDataArr = Array(week_labels?.length).fill(0)
      let week_labels_arr = week_labels.map((element) => element.toUpperCase())

      switch (action?.payload?.requestPayload?.filter) {
        case 'DAILY':
          modified_arr =
            action?.payload?.response?.map((element) => {
              const hour = element?.hour_group
              return {
                count: element?.count,
                hour: `${hour}:00`
              }
            }) || []

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.hour)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        case 'WEEKLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response
          modified_arr?.forEach((element) => {
            const index = week_labels_arr?.findIndex(
              (_element) => _element === element?.day_of_week?.trim()
            )

            if (index !== -1) {
              weeklyDataArr[index] = element?.count
            }
          })
          state.getDailyActiveUsers.data.datasets[0].data = weeklyDataArr
          break

        case 'MONTHLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month_group).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month: month,
              count: element?.count
            }
          })

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.month)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        default:
          state.getDailyActiveUsers.data.labels = []
          state.getDailyActiveUsers.data.datasets[0].data = [
            ...initialState.getDailyActiveUsers.data.datasets[0].data
          ]
          break
      }
    })
    builder.addCase(get_daily_active_users.rejected, (state) => {
      state.getDailyActiveUsers.loading = false
    })

    // get total revenue graph
    builder.addCase(get_total_revenue_graph.pending, (state) => {
      state.getTotalRevenueGraph.loading = true
    })
    builder.addCase(get_total_revenue_graph.fulfilled, (state, action) => {
      let modified_arr = []
      switch (action?.payload?.requestPayload?.filter) {
        case 'MONTHLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month,
              revenue: element?.revenue
            }
          })

          state.getTotalRevenueGraph.data.labels = modified_arr.map((item) => item.month)
          state.getTotalRevenueGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.revenue
          )
          break

        case 'YEARLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const year = new Date(element?.year).getFullYear()
            return {
              year,
              revenue: element?.revenue
            }
          })

          state.getTotalRevenueGraph.data.labels = modified_arr.map((item) => item.year)
          state.getTotalRevenueGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.revenue
          )
          break

        default:
          break
      }

      state.getTotalRevenueGraph.data.datasets[0].label =
        action.payload.requestPayload?.flag === 'TE' ? 'Tele Expertise' : 'Second Opinion'
      state.getTotalRevenueGraph.loading = false
    })
    builder.addCase(get_total_revenue_graph.rejected, (state) => {
      state.getTotalRevenueGraph.loading = false
    })

    // get members subscribed graph
    builder.addCase(get_members_subscribed_graph.pending, (state) => {
      state.getMembersSubscribedGraph.loading = true
    })
    builder.addCase(get_members_subscribed_graph.fulfilled, (state, action) => {
      let modified_arr = []
      switch (action?.payload?.requestPayload?.filter) {
        case 'MONTHLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month,
              no_of_subscription: element?.no_of_subscription
            }
          })

          state.getMembersSubscribedGraph.data.labels = modified_arr.map((item) => item.month)
          state.getMembersSubscribedGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.no_of_subscription
          )
          break

        case 'YEARLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const year = new Date(element?.year).getFullYear()
            return {
              year,
              no_of_subscription: element?.no_of_subscription
            }
          })

          state.getMembersSubscribedGraph.data.labels = modified_arr.map((item) => item.year)
          state.getMembersSubscribedGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.no_of_subscription
          )
          break

        default:
          break
      }

      state.getMembersSubscribedGraph.loading = false
    })
    builder.addCase(get_members_subscribed_graph.rejected, (state) => {
      state.getMembersSubscribedGraph.loading = false
    })

    // get total communities created
    builder.addCase(get_total_communities_created_graph.pending, (state) => {
      state.getTotalCommunitiesCreatedGraph.loading = true
    })
    builder.addCase(get_total_communities_created_graph.fulfilled, (state, action) => {
      let modified_arr = []
      switch (action?.payload?.requestPayload?.filter) {
        case 'MONTHLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month,
              no_of_communities: element?.no_of_communities
            }
          })

          state.getTotalCommunitiesCreatedGraph.data.labels = modified_arr.map((item) => item.month)
          state.getTotalCommunitiesCreatedGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.no_of_communities
          )
          break

        case 'YEARLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const year = new Date(element?.year).getFullYear()
            return {
              year,
              no_of_communities: element?.no_of_communities
            }
          })

          state.getTotalCommunitiesCreatedGraph.data.labels = modified_arr.map((item) => item.year)
          state.getTotalCommunitiesCreatedGraph.data.datasets[0].data = modified_arr.map(
            (item) => item.no_of_communities
          )
          break

        default:
          break
      }

      state.getTotalCommunitiesCreatedGraph.loading = false
    })
    builder.addCase(get_total_communities_created_graph.rejected, (state) => {
      state.getTotalCommunitiesCreatedGraph.loading = false
    })

    // get patient demographics
    builder.addCase(get_patient_demographics.pending, (state) => {
      state.getPatientDemographics.loading = true
    })
    builder.addCase(get_patient_demographics.fulfilled, (state, action) => {
      state.getPatientDemographics.loading = false
      state.getPatientDemographics.data = action?.payload?.response
    })
    builder.addCase(get_patient_demographics.rejected, (state) => {
      state.getPatientDemographics.loading = false
    })

    // get physician demographics
    builder.addCase(get_physician_demographics.pending, (state) => {
      state.getPhysicianDemographics.loading = true
    })
    builder.addCase(get_physician_demographics.fulfilled, (state, action) => {
      state.getPhysicianDemographics.loading = false
      state.getPhysicianDemographics.data = action?.payload?.response
    })
    builder.addCase(get_physician_demographics.rejected, (state) => {
      state.getPhysicianDemographics.loading = false
    })

    // search physician
    builder.addCase(search_physician.pending, (state) => {
      state.getSearchPhysicianList.loading = true
    })
    builder.addCase(search_physician.fulfilled, (state, action) => {
      state.getSearchPhysicianList.loading = false
      const updatedPhysiciansData = action.payload?.response?.data?.map((item) => {
        return {
          id: item?.id,
          name: `${item?.first_name} ${item?.last_name}`,
          speciality: item?.physician_hcp_info?.specialty_type?.display_name,
          city: item?.address?.city,
          country: item?.address?.country,
          status: item?.status,
          label: `${item?.first_name} ${item?.last_name} ${item?.physician_hcp_info?.specialty_type?.display_name ? `| ${item?.physician_hcp_info?.specialty_type?.display_name}` : ''} ${item?.address?.city ? `| ${item?.address?.city}` : ''} ${item?.address?.country ? `| ${item?.address?.country}` : ''}`,
          value: `${item?.first_name}_${item?.last_name}_${item?.id}`,
          physician_url: item?.profile_url,
          user_no: item?.user_no
        }
      })
      state.getSearchPhysicianList.data = updatedPhysiciansData
    })
    builder.addCase(search_physician.rejected, (state) => {
      state.getSearchPhysicianList.loading = false
    })

    // search community
    builder.addCase(search_community.pending, (state) => {
      state.getSearchCommunityList.loading = true
    })
    builder.addCase(search_community.fulfilled, (state, action) => {
      state.getSearchCommunityList.loading = false
      const updatedCommunitiesData = action.payload?.response?.data?.map((item) => {
        return {
          id: item?.id,
          name: item?.community_title,
          label: `${item?.community_title} | ${item?.community_id} | ${item?.community_type}`,
          value: `${item?.community_title}_${item?.community_id}`,
          status: item?.status,
          url: item?.community_url,
          users_count: item?.users_count,
          community_id: item?.community_id
        }
      })
      state.getSearchCommunityList.data = updatedCommunitiesData
    })
    builder.addCase(search_community.rejected, (state) => {
      state.getSearchCommunityList.loading = false
    })

    // get total requests created
    builder.addCase(get_total_requests_created.pending, (state) => {
      state.getTotalRequestsCreated.loading = true
    })
    builder.addCase(get_total_requests_created.fulfilled, (state, action) => {
      let modified_arr = []
      switch (action?.payload?.requestPayload?.filter?.group_by) {
        case 'MONTHLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month,
              requests: element?.requests
            }
          })

          state.getTotalRequestsCreated.data.labels = modified_arr.map((item) => item.month)
          state.getTotalRequestsCreated.data.datasets[0].data = modified_arr.map(
            (item) => item.requests
          )
          break

        case 'YEARLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const year = new Date(element?.year).getFullYear()
            return {
              year,
              requests: element?.requests
            }
          })

          state.getTotalRequestsCreated.data.labels = modified_arr.map((item) => item.year)
          state.getTotalRequestsCreated.data.datasets[0].data = modified_arr.map(
            (item) => item.requests
          )
          break

        default:
          break
      }

      state.getTotalRequestsCreated.data.datasets[0].label =
        action.payload.requestPayload?.flag === 'TE' ? 'Tele Expertise' : 'Second Opinion'
      state.getTotalRequestsCreated.loading = false
    })
    builder.addCase(get_total_requests_created.rejected, (state) => {
      state.getTotalRequestsCreated.loading = false
    })

    // get total requests accepted
    builder.addCase(get_total_requests_accepted.pending, (state) => {
      state.getTotalRequestsAccepted.loading = true
    })
    builder.addCase(get_total_requests_accepted.fulfilled, (state, action) => {
      let modified_arr = []
      switch (action?.payload?.requestPayload?.filter?.group_by) {
        case 'MONTHLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month,
              requests: element?.requests
            }
          })

          state.getTotalRequestsAccepted.data.labels = modified_arr.map((item) => item.month)
          state.getTotalRequestsAccepted.data.datasets[0].data = modified_arr.map(
            (item) => item.requests
          )
          break

        case 'YEARLY':
          modified_arr = action?.payload?.response?.map((element) => {
            const year = new Date(element?.year).getFullYear()
            return {
              year,
              requests: element?.requests
            }
          })

          state.getTotalRequestsAccepted.data.labels = modified_arr.map((item) => item.year)
          state.getTotalRequestsAccepted.data.datasets[0].data = modified_arr.map(
            (item) => item.requests
          )
          break

        default:
          break
      }

      state.getTotalRequestsAccepted.data.datasets[0].label =
        action.payload.requestPayload?.flag === 'TE' ? 'Tele Expertise' : 'Second Opinion'
      state.getTotalRequestsAccepted.loading = false
    })
    builder.addCase(get_total_requests_accepted.rejected, (state) => {
      state.getTotalRequestsAccepted.loading = false
    })

    // get requests counts
    builder.addCase(get_requests_counts.pending, (state) => {
      state.getRequestsCounts.loading = true
    })
    builder.addCase(get_requests_counts.fulfilled, (state, action) => {
      state.getRequestsCounts.data = action.payload.response
      state.getRequestsCounts.loading = false
    })
    builder.addCase(get_requests_counts.rejected, (state) => {
      state.getRequestsCounts.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { clearPhysiciansData, clearCommunitiesData } = synappAdminDashboardSlice.actions

export default synappAdminDashboardSlice.reducer
