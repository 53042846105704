import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { languageData } from '../constants'

export const LanguageContext = createContext()

const defaultLanguage = {
  value: 'en',
  label: 'English',
  active: true
}

const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)
  const { i18n } = useTranslation()

  useEffect(() => {
    const storedLang = localStorage.getItem('i18nextLng')
    if (storedLang && ['en', 'fr'].includes(storedLang)) {
      const activeLang = Object.values(languageData).find((lang) => lang.value === storedLang)
      if (activeLang) setSelectedLanguage(activeLang)
    } else {
      localStorage.setItem('i18nextLng', defaultLanguage.value)
      i18n.changeLanguage(defaultLanguage.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateLanguage = useCallback(
    (newLang) => {
      if (['en', 'fr'].includes(newLang.value)) {
        const updatedLanguages = Object.values(languageData).map((lang) => ({
          ...lang,
          active: lang.value === newLang.value
        }))
        const activeLang = updatedLanguages.find((lang) => lang.active)
        setSelectedLanguage(activeLang)
        i18n.changeLanguage(activeLang.value)
        localStorage.setItem('i18nextLng', activeLang.value)
      }
    },
    [i18n]
  )

  return (
    <LanguageContext.Provider value={{ selectedLanguage, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useLanguageChanger = () => useContext(LanguageContext)
export default LanguageProvider
