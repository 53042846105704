import RequireAuth from 'app/requireAuth'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import ErrorBoundary from 'components/common/error-boundary'
import PrimaryLayout from 'components/layouts/primary-layout'
import UserDetailsProvider from 'context/UserDetailsProvider'
import { Navigate } from 'react-router-dom'
import * as lazy from './lazyComponents'
import { roles } from '../../constants'
import CaseLayout from 'components/layouts/case-layout'
import MainLayout from 'components/layouts/main-layout'

const CommonWrapper = ({ Layout, allowedPermissions }) => {
  return (
    <UserDetailsProvider>
      <RequireAuth allowedRoles={[roles.patient]} allowedPermissions={allowedPermissions}>
        <Layout />
      </RequireAuth>
    </UserDetailsProvider>
  )
}

export const patient_routes = [
  {
    element: (
      <CommonWrapper
        Layout={CaseLayout}
        allowedPermissions={[
          {
            role: roles.patient,
            permission: 'Create Request'
          }
        ]}
      />
    ),
    children: [
      {
        path: AppRoutingConfig.CREATE_SECOND_OPINION_CASE,
        element: <lazy.CreateSecondOpinionCase />
      }
    ]
  },

  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={MainLayout} />,
    children: [
      {
        path: AppRoutingConfig.APPOINTMENTS,
        element: <lazy.Appointments />
      }
    ]
  },
  {
    path: AppRoutingConfig.SETTINGS,
    element: (
      <CommonWrapper
        Layout={PrimaryLayout}
        allowedPermissions={[{ role: roles.patient, permission: 'Manage User Settings' }]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.PROFILE_INFORMATION} replace />
      },
      {
        path: AppRoutingConfig.PAYMENT_INFORMATION,
        element: <lazy.PaymentInformation />
      }
    ]
  }
]
